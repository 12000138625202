<template>
	<span>
        <v-data-table
            :loading="loading"
            :headers="headers"
            :no-data-text="$t('no-data-available')"
            :loading-text="$t('loading')"
            :headers-length="headers.length"
            :search="appBarSearch"
            :items="filteredUsers"
            :items-per-page="-1"
            sort-by="userId"
            item-key="id"
            group-by="hospitalName"
            fixed-header
            dense
            style="cursor: pointer"
            :height="pageHeight(135)"
            class="ma-3"
            :footer-props="{
                showCurrentPage: false,
                showFirstLastPage: false,
                itemsPerPageOptions: [filteredUsers.length],
                itemsPerPageText: null
            }">
            <template v-slot:item="{ item }">
                <tr @click="userDetails(item)">
                    <td style="text-align: start">
                        {{ item.userId }}
                    </td>
                    <td style="text-align: start">
                        {{ item.userFullname }}
                    </td>
                    <td style="text-align: start">
                        {{ item.email }}
                    </td>
                    <td style="text-align: start">
                        <v-icon small :style="item.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
                    </td>

                    <!-- <td>
                        <div class="table-cols">
                            <div class="image-container">
                                <img height="24px" :src="item.status === 1 ? src.patient_black : src.patient_white" />
                            </div>
                            <div class="text-container">
                            <span class="staff-name">{{ item.fullname }}</span>
                            <span>{{ item.sex }}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                            <div class="table-cols2">
                            <span>{{ item.patientMetadata?.medicalRegistrationNumber }}</span>
                            </div>
                    </td>
                    <td>
                        <div class="table-cols2">
                            <p >{{ item.age }}</p>
                        </div>
                    </td>
                    <td>
                        <div class="table-cols2">
                            <img :src="mailPurple" height="25px">
                            <span class="staff-email">{{ item.email }}</span>
                        </div>
                    </td>
                    <td>
                        <div class="table-cols3">
                            <img :src="phonePurple" height="25px">
                            <span class="staff-phone">{{ item.phone }}</span>
                        </div>
                    </td>
                    <td>
                            <v-icon small :style="item.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
                    </td>
                    <td>
                        <v-icon v-if="item.status !== 0" :disabled="!item.medTeam" @click.stop="sendActivationEmail(item)" color="primary">
                        mdi-email
                        </v-icon>
                    </td> -->
                </tr>
            </template>
        </v-data-table>
    </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { patientBlack, videocallBlack, mailPurple, phonePurple, patientSecondary } from '@/assets';
// import { AsYouType } from 'libphonenumber-js';

export default {
	data () {
		return {
			search: '',
			loading: true,
			src: {
				patient_black: patientBlack,
				patient_white: patientSecondary,
			},
			dialog: false,
			filteredUsers: [],
			headerClass: 'black_inner--text bg-gray',
			videocallBlack: videocallBlack,
			mailPurple: mailPurple,
			phonePurple: phonePurple,
			medicalInstitutions: [],
			selectedMedicalInstitution: {},
		};
	},
	async mounted () {
		await this.getMedicalInstitutions();
		await this.getPatientListForMI(-1);
	},
	computed: {
		...mapState({
			patientsListForMedicalInstitution: (state) => state.sysadmin.patientsListForMedicalInstitution,
			combinedHospitalsPatients: (state) => state.sysadmin.combinedHospitalsPatients,
			medicalInstitutionsFromRepo: (state) =>	state.medicalInstitutions.medicalInstitutions,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			pageHeight: 'pageHeight',
			delay: 'authentication/getDelay',
			appBarSearch: 'filterbar/getSearch',
			appBarRole: 'filterbar/getRole',
			appBarMedicalInstitution: 'filterbar/getMedicalInstitution',
		}),
		headers () {
			const headers = [
				{ text: 'Patient Id', value: 'userId', class: 'table-header' },
				{ text: 'Patient', value: 'userFullname',	class: 'table-header' },
				{ text: 'Email', value: 'email', class: 'table-header' },
				{ text: 'Status', value: 'status', class: 'table-header' },
			];
			return headers;
		},
	},
	methods: {
		userDetails (item) {
			if (!item.medTeam) {
				this.$store.commit('alerts/add', {
					id: 'addedOrganization',
					type: 'error',
					color: 'main_red',
					message: this.$t('medicalTeamCheck'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$router.push({
					name: 'Patient Details',
					params: { id: `${item.id}` },
				});
			}
		},
		async getPatientListForMI (medicalInstitutionId) {
			if (medicalInstitutionId !== null && medicalInstitutionId !== undefined) {
				// const object = { filterCase: 'ALL', medicalInstitutionId: parseInt(this.$route.params.id) };
				const object = { filterCase: 'ALL', medicalInstitutionId: medicalInstitutionId };
				if (this.roleName === 'SYSTEM_ADMIN') {
					await this.$store.dispatch('sysadmin/getPatientsForMedicalInstitution', object).then(() => {
						this.loading = false;

						this.filteredUsers = this.combinedHospitalsPatients;
					});
				}
			}
		},
		async getMedicalInstitutions () {
			await this.$store
				.dispatch('medicalInstitutions/getMedicalInstitutions')
				.then(() => {
					this.medicalInstitutions = this.medicalInstitutionsFromRepo;
					this.loading = false;
				});
		},
		async sendActivationEmail (item) {
			const validationType = item.status !== 1 ? 'USER_CONFIRMATION' : 'RESET_PASSWORD';
			const sendValidationEmail = { email: item.email, validationType: validationType };
			await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
				.then(res => {
					if (res.resFlag) {
						this.$store.commit('alerts/add', {
							id: 'addedAdmin',
							type: 'success',
							color: 'main_green',
							message: res.msg,
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					} else {
						this.$store.commit('alerts/add', {
							id: 'errorAddingAdmin',
							type: 'error',
							color: 'main_red',
							message: res.msg,
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					}
				});
		},
	},
	watch: {
		async appBarMedicalInstitution (val) {
			await this.getPatientListForMI(val);
		},
	},
};
</script>
